import { useState, useEffect } from 'react'
import axios from 'axios'

// styles
import Button from 'react-bootstrap/Button'

const ThankYou = ({
  agentCode,
  forceNumber,
  armsOfService,
  rankTitle,
  fullNames,
  surname,
  IDNumber,
  mainID,
  cellNumber,
  altCellNumber,
  landlineNumber,
  emailAddress,
  emailAddresschecked,
  streetNumber,
  streetName,
  complexName,
  unitNumber,
  suburb,
  town,
  postalCode,
  unitName,
  unitSwitchBoardNumber,
  maritalStatus,
  monthlyPremium,
  spouseTitle,
  spouseFullNames,
  spouseSurname,
  spouseID,
  spouseIdFile,
  childrenAmount,
  childTitle,
  childFullNames,
  childSurname,
  childID,
  childTitle2,
  childFullNames2,
  childSurname2,
  childID2,
  childTitle3,
  childFullNames3,
  childSurname3,
  childID3,
  childTitle4,
  childFullNames4,
  childSurname4,
  childID4,
  childTitle5,
  childFullNames5,
  childSurname5,
  childID5,
  childTitle6,
  childFullNames6,
  childSurname6,
  childID6,
  childrenIds,
  pbTitle,
  pbFullNames,
  pbSurname,
  pbID,
  pbCellNumber,
  pbIdData,
  setPbIdData,
  nlTitle,
  nlFullNames,
  nlSurname,
  nlIDNumber,
  nlCellNumber,
  nlIdData,
  setNlIdData,
  paymentMethod,
  signature,
  base64Data,
  setBase64Data,
  bankingDetailsDO,
  idParams,
  dpip,
  dpipDetails,
  fppo,
  fppoDetails,
  closeDpipFppo,
  closeDpipFppoDetails,
  pbLandline,
  pbRelationship,
  nlLandline,
  nlRelationship,
  sourceIncome,
  sourceIncomeDetails,
  mainAge,
  spouseAge,
  childAge,
  childAge2,
  childAge3,
  childAge4,
  childAge5,
  childAge6,
  pbAge,
  nlAge,
  spouseCell,
  agsb,
  agsbwide,
  rhcb,
  rhcbwide,
  cgsb,
  cgsbwide,
  asb,
  asbwide,
  other,
  otherwide,
  otherDetails,
  otherDetailswide,
  prevCancelation,
  prevWaiting,
  prevCanYes,
  marketingConsent,
  memberRelation,
  relationship,
  name,
  relationSurname,
  title,
  dateOfBirth,
  age,
  benefit,
  premium,
  memberRelation2,
  relationship2,
  name2,
  relationSurname2,
  title2,
  dateOfBirth2,
  age2,
  benefit2,
  premium2,
  memberRelation3,
  relationship3,
  name3,
  relationSurname3,
  title3,
  dateOfBirth3,
  age3,
  benefit3,
  premium3,
  memberRelation4,
  relationship4,
  name4,
  relationSurname4,
  title4,
  dateOfBirth4,
  age4,
  benefit4,
  premium4,
  memberRelation5,
  relationship5,
  name5,
  relationSurname5,
  title5,
  dateOfBirth5,
  age5,
  benefit5,
  premium5,
  memberRelation6,
  relationship6,
  name6,
  relationSurname6,
  title6,
  dateOfBirth6,
  age6,
  benefit6,
  premium6,
  memberRelation7,
  relationship7,
  name7,
  relationSurname7,
  title7,
  dateOfBirth7,
  age7,
  benefit7,
  premium7,
  memberRelation8,
  relationship8,
  name8,
  relationSurname8,
  title8,
  dateOfBirth8,
  age8,
  benefit8,
  premium8,
  extendedMonthlyPremium,
  extendedPaymentMethod,
  otherExtendedText,
  extendedBankingDetailsDO
}) => {

  const postToApi = async () => {
    var config = {
      method: 'post',
      url: 'https://apiv2.msgl.ink/',
      headers: {
        'content-type': 'application/json'
      },
      data: {
        agentCode,
        forceNumber,
        armsOfService,
        rankTitle,
        fullNames,
        surname,
        IDNumber,
        mainID,
        mainAge,
        cellNumber,
        altCellNumber,
        landlineNumber,
        emailAddress,
        emailAddresschecked,
        streetNumber,
        streetName,
        complexName,
        unitNumber,
        suburb,
        town,
        postalCode,
        unitName,
        unitSwitchBoardNumber,
        maritalStatus,
        monthlyPremium,
        spouseTitle,
        spouseFullNames,
        spouseSurname,
        spouseID,
        spouseIdFile,
        childrenAmount,
        childTitle,
        childFullNames,
        childSurname,
        childID,
        childTitle2,
        childFullNames2,
        childSurname2,
        childID2,
        childTitle3,
        childFullNames3,
        childSurname3,
        childID3,
        childTitle4,
        childFullNames4,
        childSurname4,
        childID4,
        childTitle5,
        childFullNames5,
        childSurname5,
        childID5,
        childTitle6,
        childFullNames6,
        childSurname6,
        childID6,
        childrenIds,
        pbTitle,
        pbFullNames,
        pbSurname,
        pbID,
        pbCellNumber,
        pbIdData,
        setPbIdData,
        nlTitle,
        nlFullNames,
        nlSurname,
        nlIDNumber,
        nlCellNumber,
        nlIdData,
        setNlIdData,
        paymentMethod,
        signature,
        base64Data,
        bankingDetailsDO,
        idParams,
        pbLandline,
        pbRelationship,
        nlLandline,
        nlRelationship,
        sourceIncome,
        sourceIncomeDetails,
        spouseAge,
        childAge,
        childAge2,
        childAge3,
        childAge4,
        childAge5,
        childAge6,
        pbAge,
        nlAge,
        spouseCell,
        prevCanYes,
        marketingConsent,
        agsb,
        agsbwide,
        rhcb,
        cgsb,
        asb,
        other,
        otherDetails,

      }
    };

    await axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const routeChange = () =>{ 
    window.location.assign('http://localhost:3000/');
  }

  useEffect(()=>{
    postToApi();
  })

  return (
    <div className="page">
     <div className='page'>
        <h6 style={{ fontWeight: '600', marginTop: '25px', textAlign: 'center', color: '#BB1A1B', marginBottom: '25px' }}>Thank you very much. We will be in contact shortly</h6>

        <div style={{ textAlign: 'center' }}>
          <p style={{ textAlign: 'center' }}>Go to Home Page please click on below button</p>
          <Button variant='danger w-50' style={{ fontWeight: '600', background: '#BB1A1B', border: 'none' }} onClick={routeChange}>
            Home
          </Button>
        </div>
      </div>
    </div>
  )
}
export default ThankYou
